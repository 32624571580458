import React from "react";
// import logo from '../../images/logo.png'
import logo from "../../assets/images/logo.svg";
import { useLocation } from "react-router-dom";
const Header = () => {
  const url = "#";
  const location = useLocation();

  const isMenuOrMeal =
    location.pathname === "/menu" || /^\/meal\/[^/]+$/.test(location.pathname);

  return (
    <>
      {isMenuOrMeal ? (
        " "
      ) : (
        <nav
          className="navbar"
          style={{
            backgroundColor: "#37BD6B",
            textAlign: "center",
            margin: "0",
            display: "block",
            position: "relative",
            zIndex: 999,
            height: "80px",
          }}
        >
          <div className="">
            <img
              style={{ height: "40px", width: "auto" }}
              src={logo}
              alt="...."
            />
          </div>
        </nav>
      )}
    </>
  );
};

export default Header;
