import React, { useEffect, useState } from "react";
import "./meal.css";

import { useParams, useNavigate, useLocation } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../../axiosConfig/axios";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { Minus, Plus } from "lucide-react";
import axios from "axios";

const Meal = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  let [count, setCount] = useState(1);
  const [Product, setProduct] = useState([]);
  const [Sizes, setSizes] = useState([]);
  const [Addons, setAddons] = useState([]);
  const [Options, setOptions] = useState([]);
  let [subPrice, setsubPrice] = useState(0);
  let [totalPrice, setTotalPrice] = useState(0);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [selectedSize, setSelectedSize] = useState({
    summary: { subPrice: 0 },
  });

  useEffect(() => {
    setTotalPrice(
      parseFloat(subPrice) +
        (parseFloat(selectedSize.summary.subPrice) || 0) * count
    );
  }, [selectedSize.summary.subPrice, subPrice, count]);

  localStorage.setItem("quantity", count);

  function incrementCount() {
    count = count + 1;
    setCount(count);
  }

  function decrementCount() {
    if (count > 1) {
      setCount(count - 1);
    }
  }

  const requestBody = {
    name: name,
    email: email,
    mobile: mobile,
  };

  const requestproductBody = {
    productId: Product.id,
    qty: count,
    options: Options,
  };

  const token = localStorage.getItem("token");
  const addToCart = () => {
    axios
      .post(
        "https://development.feastap.com/api/user/cart",
        requestproductBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(function (res) {
        console.log(res.data);
        console.log(res.data.data.products);

        toast.success(" Added successfully .", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });

        setTimeout(() => {
          // navigate("/afterAddingOrder");
        }, 2000); // Wait for 2 seconds
      })
      .catch((error) => {
        if (error.response) {
          console.log(error.response.data.message);
          console.log("server error");

          toast.error(error.response.data.message, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        } else if (error.request) {
          console.log("network error");

          toast.error("network error! ,please check your network ", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      });
  };

  const params = useParams();

  const getProductDetails = async () => {
    const { data } = await axiosInstance.get(
      `user/menu/singleItem/${params.id}`
    );
    console.log(data);

    setProduct(data.data);
    console.log(data.data);
    setSizes(data.data.options[0].variation);
    setAddons(data.data.options[1].variation);
  };

  useEffect(() => {
    getProductDetails();
  }, []);

  return (
    <div className="meal-product">
      <div className="meal-img">
        <img src={Product.image} alt={Product.name} />
      </div>

      <div className="meal-content">
        <div className="meal">
          <h3>{Product.name}</h3>
          <p>{Product.price} ر.س</p>
        </div>
        <div className="meal">
          <h3>السعرات الحرارية</h3>
          <p>{Product.calories} سعرة حرارية</p>
        </div>
        <div>
          <p className="desc">{Product.description}</p>
        </div>
      </div>

      <hr />

      <div className="size">
        <h3>السعر و الحجم</h3>

        <div className="meal-form">
          <Form
            style={{
              padding: "0",
              width: "50%",
              backgroundColor: "transparent",
            }}
          >
            <div key={"reverse-radio"}>
              {Sizes?.map((Size, index) => {
                return (
                  <Form.Check
                    style={{ color: "#838383" }}
                    reverse
                    key={index}
                    label={Size.name}
                    name="group1"
                    type="radio"
                    id={`reverse-radio-${index}`}
                    // defaultValue={selectedSize === Size}
                    onChange={() => {
                      setSelectedSize(Size);
                      setOptions((prevOptions) => [...prevOptions, Size.id]);
                    }}
                  />
                );
              })}
            </div>
          </Form>

          {Sizes?.map((size, i) => {
            return (
              <p style={{ color: "#838383" }} key={i}>
                {size.summary.subPrice} ر.س
              </p>
            );
          })}
        </div>
      </div>

      <hr />

      <div className="addon">
        <h3>أي اضافات</h3>

        <div className="meal-form">
          <Form
            style={{
              padding: "0",
              width: "50%",
              backgroundColor: "transparent",
            }}
          >
            <div key={"reverse-checkbox"} className="mb-3">
              {Addons?.map((Addon, index) => {
                {
                  /* previous code */
                }
                const handleCheckboxChange = (event) => {
                  if (event.target.checked == true) {
                    setOptions((prevOptions) => [...prevOptions, Addon.id]);
                    setsubPrice(
                      (prevSubPrice) =>
                        parseFloat(prevSubPrice) +
                        parseFloat(Addon.summary.subPrice)
                    );
                    {
                      /* setsubPrice(parseFloat((subPrice )+ Addon.summary.subPrice)); */
                    }
                  } else if (event.target.checked == false) {
                    setOptions((prevOptions) =>
                      prevOptions.filter((optionId) => optionId !== Addon.id)
                    );
                    setsubPrice(subPrice - Addon.summary.subPrice);
                  }
                };

                return (
                  <div key={index}>
                    <Form.Check
                      style={{ color: "#838383" }}
                      reverse
                      label={Addon.name}
                      name="group1"
                      type="checkbox"
                      id={`checkbox-${index}`}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                );
              })}
            </div>
          </Form>

          {Addons?.map((addon, i) => {
            return (
              <p style={{ color: "#838383" }} key={i}>
                {addon.summary.subPrice} ر.س
              </p>
            );
          })}
        </div>
      </div>

      <hr />

      <div style={{ padding: "0 20px" }}>
        <h3
          style={{
            fontFamily: "Tajawal",
            fontSize: "16px",
            fontWeight: "900",
          }}
        >
          ملاحظات{" "}
        </h3>
        <FloatingLabel
          controlId="floatingTextarea2"
          className="FloatingLabel"
          label="أضف ملاحظاتك على الاوردر"
          style={{
            fontFamily: "Tajawal",
            fontSize: "12px",
            fontWeight: "400",
            color: "#838383",
          }}
        >
          <Form.Control
            as="textarea"
            placeholder="Leave a comment here"
            style={{ height: "100px", marginBottom: "25px" }}
          />
        </FloatingLabel>
      </div>

      <div
        style={{
          padding: "0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          style={{
            backgroundColor: "#37BD6B",
            textAlign: "center",
            margin: "0",
            border: "none",
            padding: "10px 7px",
          }}
          onClick={(event) => {
            // if (totalPrice >= Product.price) {
            event.preventDefault();
            localStorage.setItem("hasProduct", true);
            localStorage.setItem("product", requestproductBody);
            if (token) {
              addToCart();
            } else {
              // * Navigate for login / register
              navigate("/login");
            }
          }}
        >
          أضف الى السلة {"         "} {totalPrice} ر.س
        </Button>

        <div
          className="count"
          style={{
            backgroundColor: "rgb(243 243 243)",
            textAlign: "center",
            border: "none",
            display: "flex",
            alignItems: "center",
            float: "left",
            borderRadius: "8px",
          }}
        >
          <Button
            style={{
              borderColor: "#37BD6B",
              color: "#37BD6B",
              padding: "2px",
              fontWeight: "500",
              backgroundColor: "white",
              alignContent: "center",
            }}
            onClick={incrementCount}
          >
            <Plus />
          </Button>
          <div
            style={{
              padding: " 0 9px",
              fontSize: " 17px",
              fontWeight: "700",
            }}
          >
            {count}
          </div>
          <Button
            // type="button"
            // className="btn2"
            style={{
              borderColor: "#37BD6B",
              color: "#37BD6B",
              fontWeight: "500",
              // height: "0%",
              backgroundColor: "white",
              padding: "2px",
              alignContent: "center",
            }}
            onClick={decrementCount}
            disabled={count === 1}
          >
            <Minus />
          </Button>
        </div>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Meal;
