import React, { useEffect, useState } from "react";
import "./card.css";
import trash from "../../assets/images/trash.png";
import { Button } from "react-bootstrap";
import $ from "jquery";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-use-history";
import axiosInstance from "../../axiosConfig/axios";
import { useNavigate } from "react-router-dom";
import { localStorageService } from "../../Helpers/localstorageService";
import greenlocat from "../../assets/images/greenLocat.png"
const Card = () => {
  // const url = '#';

  const navigate = useNavigate();

  const [menuProducts, setMenuProducts] = useState([]);
  const [Promotion, setPromotion] = useState("");
  const [orderId, setOrderId] = useState(0);
  const [SubTotal, setSubTotal] = useState("");
  const [TaxValue, setTaxValue] = useState("");
  const [Saving, setSaving] = useState("");
  const [Total, setTotal] = useState("");
  const [counts, setCounts] = useState([]);
  let history = useHistory();

  const url = "https://development.feastap.com/api/";
  const token = localStorage.getItem("accessToken");
  const tableId = localStorage.getItem("table");

  useEffect(() => {
    axiosInstance
      .get("user/cart")
      .then(function (res) {
console.log("productsssssss")
console.log(res.data.data.products)
        setMenuProducts(res.data.data.products);
        setTotal(res.data.data.summary.total);
        setSubTotal(res.data.data.summary.subTotal);
        setTaxValue(res.data.data.summary.taxValue);
        setSaving(res.data.data.summary.saving);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  const deleteProduct = (id) => {
    // console.log(id);

    axiosInstance
      .post(`user/cart/removeItem/${id}`)
      .then(function (res) {
        // console.log(res.data.message);
        // console.log(token);
        // If you want to update your UI or take some action after the item is deleted,
        // you can do it here.
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const deleteAllProduct = () => {
    axiosInstance
      .post("user/cart/emptyCart")
      .then(function (res) {
        // console.log(res.data.message);

        // If you want to update your UI or take some action after the item is deleted,
        // you can do it here.
        window.location.reload();
      })
      .catch(function (err) {
        console.log(err);
      });
    // .finally(function () {
    //     // This block will be executed whether the request succeeds or fails.
    // });
  };

  const requestBody = {
    promotion: Promotion,
  };

  const requestBody1 = {
    table_id: tableId,
  };

  const applyPromotion = () => {
    axiosInstance
      .post("user/cart/applyPromotion", requestBody)
      .then(function (res) {
        console.log(res.data.message);

        // {Summary.saving}

        toast.success(res.data.message, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const makeOrder = () => {
    axiosInstance
      .post("user/order/make-order", requestBody1)
      .then(function (res) {
        console.log(res.data); // Check the structure of the response
        localStorageService.setItem("orderId", res.data.order_id);
        console.log(res.data);
  
        // Make sure res.data.qrCode exists
        if (res.data.qrCode) {
          toast.success(".....wait, You are being transferred", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
  
          setTimeout(() => {
            window.location.href = res.data.qrCode;
          }, 2000); // Wait for 2 seconds
        } else {
          toast.error("QR code not found in the response", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        }
      })
      .catch(function (err) {
        console.log(err);
      });
  };
  

  $('input[type="text"]').on("focus", function () {
    $(".contin").addClass("focused");
  });

  $('input[type="text"]').off("focus", function () {
    $(".contin").removeClass("focused");
  });
  function setFocused() {
    document.querySelectorAll(".contin").forEach((result) => {
      result.classList.add("focused");
    });
  }

  function unsetFocused() {
    document.querySelectorAll(".contin").forEach((result) => {
      result.classList.remove("focused");
    });
  }

  document.querySelectorAll('input[type="text"]').forEach((result) => {
    result.addEventListener("focusin", setFocused);
    result.addEventListener("focusout", unsetFocused);
  });

  const incrementCount = (index, productCartId, quntity) => {
    console.log(productCartId);

    const newCount = quntity + 1;
    const reqBody = {
      quantity: newCount,
    };

    axiosInstance
      .post(`user/cart/updateQuantity/${productCartId}`, reqBody)
      .then(function (res) {
        // console.log(res.data.data.products);

        const updatedMenuProducts = [...menuProducts]; // Create a copy of the array
        updatedMenuProducts[index].quantity = newCount; // Update the quantity property
        setMenuProducts(updatedMenuProducts);

        setTotal(res.data.data.summary.total);
        setSubTotal(res.data.data.summary.subTotal);
        setTaxValue(res.data.data.summary.taxValue);
        setSaving(res.data.data.summary.saving);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  const decrementCount = (index, productCartId, quntity) => {
    if (quntity <= 1) {
      return; // Prevent decrementing below 1
    }

    const newCount = quntity - 1;
    const reqBody = {
      quantity: newCount,
    };

    axiosInstance
      .post(`user/cart/updateQuantity/${productCartId}`, reqBody)
      .then(function (res) {
        console.log(res.data.data.products);

        const updatedMenuProducts = [...menuProducts]; // Create a copy of the array
        updatedMenuProducts[index].quantity = newCount; // Update the quantity property
        setMenuProducts(updatedMenuProducts);

        setTotal(res.data.data.summary.total);
        setSubTotal(res.data.data.summary.subTotal);
        setTaxValue(res.data.data.summary.taxValue);
        setSaving(res.data.data.summary.saving);
      })
      .catch(function (err) {
        console.log(err);
      });
  };

  return (
    <>
      <nav
        className="navbar"
        style={{
          backgroundColor: "white",
          textAlign: "center",
          margin: "0",
          display: "block",
        }}
      >
        <div className="">
          <p
            style={{
              fontSize: "18px",
              fontWeight: "500",
              color: "black",
              marginBottom: "0",
            }}
          >
            {" "}
            سلة المشتريات{" "}
          </p>
        </div>
      </nav>
<div style={{   padding: "12px",
    margin: "15px"}}>

        <h3
          style={{
            margin: "0",
            fontWeight: "500",
            fontFamily:"TajawalBold",
            fontSize: "15px",
            color:"#37BD6B",
            marginBottom: "15px",
          }}
        >
         <img src={greenlocat} height={"18px"} width={"18px"}/>
          {" "}
         البيك
          <span
            onClick={() => deleteAllProduct()}
            style={{
              float: "left",
              color: "#37BD6B",
              fontSize: "14px",
              fontWeight: "400",
              marginRight: "4px",
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
         اذهب إلى المنيو
          </span>
          
        </h3>
        </div>
      <div className="d-flex flex-column  justify-content-around">
      <div className="biggest-box">
     <p style={{
      fontWeight:400, lineHeight:"16.8px", fontSize:"16px"
     }}>
      تفاصيل الطلب
     </p>
        {menuProducts.map((menuProduct, productIndex) => {
          const subtotal = menuProduct.summary.subTotal * menuProduct.quantity;
          return (
            <div className="medium-box-one" key={productIndex}>
              <div
               
                style={{ padding: "20px " , display:"flex", alignItems:"center"}}
              >
                <img
                  src={menuProduct.image}
                  alt="Avatar"
                  style={{
                    width: "80px",
                    marginLeft: "10px",
                    borderRadius: "8px",
                    marginBottom: "15px",
                    height: "68px",
                  }}
                />

                <p
                  style={{ fontSize:"14px",  marginRight: "auto", fontWeight: "500", fontSize: "16px" }}
                >
               
                  {menuProduct.name}
                 {/* ///////////////////////////////////////// necessary to be added but not included in design */}
                  {/* <img
                    src={trash}
                    onClick={() => deleteProduct(menuProduct.productCartId)}
                    alt="Avatar"
                    style={{
                      width: "13px",
                      height: "auto",
                      float: "left",
                      cursor: "pointer",
                    }}
                  />{" "} */}
                </p>
               
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "400",
                    color: "rgb(55, 189, 107)",
                    marginRight: "auto",
                    alignSelf:" center"
                    
                  }}
                >
               
                  {subtotal} ر.س
                  </p>
                  <div style={{ backgroundColor:" #37BD6B33", marginRight:"auto",display:"flex", color:" #37BD6B", borderRadius:"4px",justifyContent:"center" , padding:"5px"}}>
                 
               x   {menuProduct.quantity}
             
                  </div>
                  {/* <div
                    className="count"
                    style={{ marginTop: "5px", display: "flex", float: "left" }}
                  >
                    <button
                      type="button"
                      className="btn2"
                      style={{
                        borderColor: "#37BD6B",
                        color: "#37BD6B",
                        fontSize: " 18px",
                        fontWeight: "500",
                        lineHeight: "0",
                        height: "17px",
                        width: "17px",
                      }}
                      onClick={() =>
                        incrementCount(
                          productIndex,
                          menuProduct.productCartId,
                          menuProduct.quantity
                        )
                      }
                    >
                      +
                    </button>
                    <div
                      style={{
                        padding: " 0 9px",
                        fontSize: " 12px",
                        fontWeight: "700",
                        color: "black",
                      }}
                    >
                      {" "}
                      {menuProduct.quantity}
                    </div>
                    <button
                      type="button"
                      className="btn2"
                      style={{
                        borderColor:
                          menuProduct.quantity === 1 ? "#cccccc" : "#37BD6B", // Change border color when count is 1
                        color:
                          menuProduct.quantity === 1 ? "#cccccc" : "#37BD6B", // Change text color when count is 1
                        cursor:
                          menuProduct.quantity === 1
                            ? "not-allowed"
                            : "pointer",
                        fontSize: " 18px",
                        fontWeight: "500",
                        lineHeight: "0",
                        height: "17px",
                        width: "17px",
                      }}
                      onClick={() =>
                        decrementCount(
                          productIndex,
                          menuProduct.productCartId,
                          menuProduct.quantity
                        )
                      }
                    >
                      -
                    </button>
                  </div> */}
   
              </div>
            </div>
          );
        })}
      </div>

      <div className="last-box">
        <h3
          style={{
            margin: "0",
            fontWeight: "400",
            fontSize: "15px",
            marginBottom: "15px",
          }}
        >
          {" "}
          تفاصيل الفاتورة{" "}
        </h3>

        {/* <div className="contin" style={{ display: "flex" }}>
          <input
            type="text"
            className={{ flex: "1 1 0%" }}
            onChange={(event) => setPromotion(event.target.value)}
            placeholder="ادخل الكود"
          />
          <input type="submit" onClick={() => applyPromotion()} value="تطبيق" />
        </div> */}

    

        <p style={{ fontSize: "13px", fontWeight: "400" }}>
          {" "}
          الاجمالي
          <p
            style={{
              fontSize: "13px",
              fontWeight: "400",
              margin: "0",
              float: "left",
            }}
          >
            {" "}
            {SubTotal} ر.س
          </p>
        </p>

        <p style={{ fontSize: "13px", fontWeight: "400" }}>
          {" "}
          الضريبة
          <p
            style={{
              fontSize: "13px",
              fontWeight: "400",
              margin: "0",
              float: "left",
            }}
          >
            {" "}
            {TaxValue} ر.س
          </p>
        </p>

        <p style={{ fontSize: "13px", fontWeight: "400" }}>
          {" "}
          الخصم
          <p
            style={{
              fontSize: "13px",
              fontWeight: "400",
              margin: "0",
              float: "left",
            }}
          >
            {" "}
            {Saving} ر.س
          </p>
        </p>

        <hr style={{ border: "2px solid #E3E3E3" }} />

        <p style={{ fontSize: "13px", fontWeight: "500", color: "#37BD6B" }}>
          {" "}
          التوتال
          <p
            style={{
              fontSize: "13px",
              fontWeight: "400",
              margin: "0",
              float: "left",
              color: "#37BD6B",
            }}
          >
            {" "}
            {Total} ر.س
          </p>
        </p>
      </div>

      <Button
        style={{
          backgroundColor: "#37BD6B",
          textAlign: "center",
  margin:"auto",
          border: "none",
          padding: "10px 7px",
          width: "91.3%",
          fontWeight: "900",
          fontFamily: "Tajawal",
        }}
        onClick={() => makeOrder()}
      >
       الذهاب للدفع
      </Button>
  
</div>
      <ToastContainer />
    </>
  );
};

export default Card;
