import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import Downloading from "../pages/downloading/downloading";
import Meal from "../pages/meal/meal";
import Card from "../pages/card/card";
import Payment from "../pages/payment/payment";
import Oncash from "../pages/oncash/oncash";
import AfterAddingOrder from "../pages/afteraddingorder/afteraddingorder";
import Login from "../pages/Login/Login";
import Register from "../pages/Register/Register";
import Otp from "../pages/Otp/Otp";
import RestaurantMenu from "../pages/RestaurantMenu/RestaurantMenu";

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,

    children: [
      {
        path: "webfeastap",
        element: <Downloading />,
      },
      {
        path: "menu",
        element: <RestaurantMenu />,
      },
      {
        path: "meal/:id",
        element: <Meal />,
      },
      {
        path: "card",
        element: <Card />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "register",
        element: <Register />,
      },
      {
        path: "otp",
        element: <Otp />,
      },
      {
        path: "oncash",
        element: <Oncash />,
      },
      {
        path: "afterAddingOrder",
        element: <AfterAddingOrder />,
      },
    ],
  },
]);
