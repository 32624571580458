import React, { useEffect, useState } from "react";
import Slider from "../../components/swiper/slider";
import locat from "../../assets/images/material-symbols_location-on.png";
import Star from "../../assets/images/star.svg";
import addressinfo from "../../assets/images/addressinfo.png";
import axiosInstance from "../../axiosConfig/axios";
import "./menu.css";
import TabBar from "./tabbar";
import { Link } from "react-router-dom";
import { Plus } from "lucide-react";

const RestaurantMenu = () => {
  const [resturantSliderImages, setResturantSliderImages] = useState([]);
  const [resturantReviews, setResturantReviews] = useState([]);
  const [resturantInfoData, setResturantInfoData] = useState({
    name: "",
    about: "",
    location: { address: "", latitude: "", longitude: "" },
    openTime: "",
    closeTime: "",
  });
  const [resturantInfo, setResturantInfo] = useState({
    name: "",
    address: "",
    distance: "",
    rating: 0,
    logo: "",
    m_categories: "",
    menuCategory: [],
    menuProducts: [],
    offers: [],
  });

  const [activeTab, setActiveTab] = useState("المنيو"); // Initial active tab
  const [activeCategory, setActiveCategory] = useState("الكل"); // Initial active category

  const restaurantId = localStorage.getItem("restaurant");

  const getData = async () => {
    const { data } = await axiosInstance.get(`user/menu/${restaurantId}`);
    // console.log(data);
    setResturantSliderImages(data?.data?.info?.resturantImages);
    setResturantInfo({
      name: data.data.info.name,
      address: data.data.info.address,
      distance: data.data.info.distance,
      rating: data.data.info.rating,
      logo: data.data.info.Logo,
      m_categories: data.data.info.m_categories,
      menuCategory: data.data.menuCategory,
      menuProducts: data.data.menuProducts,
      offers: data.data.offers,
    });
  };

  const getReviews = async () => {
    const { data } = await axiosInstance.get(`user/reviews/${restaurantId}`);
    setResturantReviews(data.data);
  };

  const getInfoData = async () => {
    const { data } = await axiosInstance.get(`user/menu/info/${restaurantId}`);
    setResturantInfoData({
      name: data.data.name,
      about: data.data.about,
      location: {
        address: data.data.location.address,
        latitude: data.data.location.latitude,
        longitude: data.data.location.longitude,
      },
      openTime: data.data.openTime,
      closeTime: data.data.closeTime,
    });
  };

  useEffect(() => {
    getData();
    getReviews();
    getInfoData();
  }, []);

  const tabs = ["المنيو", "عروض", "معلومات", "مراجعات"];
  const categories = [
    "الكل",
    ...resturantInfo.menuCategory.map((category) => category.name),
  ];

  // Filter products based on activeCategory
  const filteredProducts =
    activeCategory === "الكل"
      ? resturantInfo.menuProducts
      : resturantInfo?.menuProducts.filter(
          (product) =>
            resturantInfo.menuCategory.find(
              (category) => category.name === activeCategory
            )?.id === product.category_id
        );

  // Render content based on activeTab
  const renderTabContent = () => {
    switch (activeTab) {
      case "المنيو":
        return (
          <>
            <TabBar
              tabs={categories}
              nameOfClass="content-tap-button"
              mainClass="tab-bar2"
              onTabClick={setActiveCategory} // Pass a function to handle category changes
            />
            <div className="container-product">
              {/* Render filtered menu products content */}
              {filteredProducts && filteredProducts.length > 0 ? (
                filteredProducts.map((product) => (
                  <div key={product.id}>
                    <Link
                      to={`/meal/${product.id}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="product">
                        <div className="container-content">
                          <img src={product.image} alt={product.name} />
                          <div className="content">
                            <h3>{product.name}</h3>
                            <p>{product.description}</p>
                            <p className="price">
                              <span>{product.price}</span> ر.س
                            </p>
                          </div>
                        </div>
                        <button>
                          <Plus />
                        </button>
                      </div>
                    </Link>
                  </div>
                ))
              ) : (
                <div className="no-data">لا توجد منتجات في القائمة</div>
              )}
            </div>
          </>
        );
      case "عروض":
        return (
          <div className="container-product">
            {resturantInfo?.offers && resturantInfo.offers.length > 0 ? (
              resturantInfo.offers.map((offer) => (
                <div key={offer.id}>
                  <Link
                    to={`/meal/${offer.id}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div className="product">
                      <div className="container-content">
                        <img src={offer.image} alt={offer.name} />
                        <div className="content">
                          <h3>{offer.name}</h3>
                          <p>{offer.description}</p>
                          <p className="price">
                            <span>{offer.price}</span> ر.س
                          </p>
                        </div>
                      </div>
                      <button>
                        <Plus />
                      </button>
                    </div>
                  </Link>
                </div>
              ))
            ) : (
              <div className="no-data">لا توجد عروض متاحة حاليا</div>
            )}
          </div>
        );
      case "معلومات":
        return (
          <div className="info-restaurant">
            {/* Render additional information */}
            <h3>معلومات عن المطعم</h3>
            <p>{resturantInfoData.about}</p>
            <hr className="info" />

            <div className="location">
              <img src={locat} alt="location" />
              <div>{resturantInfoData.location.address}</div>
            </div>

            <div className="hours">
              <img src={locat} alt="location" />
              <div>
                يفتح يوميا من <span>{resturantInfoData.openTime}</span> الي{" "}
                <span>{resturantInfoData.closeTime}</span>
              </div>
            </div>
          </div>
        );
      case "مراجعات":
        return (
          <div className="container-product">
            {/* Render reviews content */}
            {resturantReviews?.map((review, index) => (
              <div key={index}>
                <div className="review">
                  <div className="content">
                    <h3>{review.username}</h3>
                    <p>{review.review}</p>
                  </div>
                  <div className="rate">
                    {/* Render stars based on the rate */}
                    <div className="icons">
                      {" "}
                      {Array.from({ length: review.rate }).map((_, i) => (
                        <img src={Star} key={i} width={15} />
                      ))}
                    </div>
                    <div className="num">{review.rate}</div>
                  </div>
                </div>
                <hr className="reviewLine" />
              </div>
            ))}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div>
        {/* Start Slider Of Restaurant Images*/}
        <div style={{ padding: "0 20px" }}>
          <Slider images={resturantSliderImages} />
        </div>
        {/* End Slider Of Restaurant Images*/}

        {/* Start Restaurant Info */}
        <div className="contain">
          <img src={resturantInfo.logo} alt="logo" className="logo" />
          <h3>{resturantInfo.name}</h3>
          <p className="m_categories">{resturantInfo.m_categories}</p>
          <div className="location">
            <img src={locat} alt="location" />
            <p>{resturantInfo.address}</p>
          </div>
          <div className="address">
            <img src={addressinfo} alt="address" />
            <p>{resturantInfo.distance}</p>
          </div>
        </div>
        {/* End Restaurant Info */}
        <hr />

        {/* Start Main Tabs */}
        <div style={{ padding: "0 20px" }}>
          <TabBar
            tabs={tabs}
            nameOfClass="main-tap-button"
            mainClass="tab-bar1"
            onTabClick={setActiveTab} // Pass a function to handle tab changes
          />
        </div>
        {/* End Main Tabs */}

        {/* Start Content Of Main Tabs */}
        <div style={{ padding: "0 20px" }}>{renderTabContent()}</div>
        {/* End Content Of Main Tabs */}
      </div>
    </>
  );
};

export default RestaurantMenu;
