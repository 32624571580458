import axios from "axios";
import store from "../store/store";
import { changeLoader } from "../store/actions/loader";
import { localStorageService } from "../Helpers/localstorageService";

const axiosInstance = axios.create({
  baseURL: "https://development.feastap.com/api/",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

//request

axiosInstance.interceptors.request.use(
  function (config) {
    const accessToken = localStorageService.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    //show loader

    store.dispatch(changeLoader(true));

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    //hide loader

    store.dispatch(changeLoader(false));
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default axiosInstance;
