import axios from "axios";
import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import * as Yup from "yup";

import { Link, useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { Eye, EyeOff, LockKeyhole, Mail } from "lucide-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  const [showPassword, setShowPassword] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const navigate = useNavigate();

  const emailOrPhoneRegExp = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$|^5\d{8}$/;

  let validationSchema = Yup.object({
    login: Yup.string()
      .matches(emailOrPhoneRegExp, "يجب إدخال بريد إلكتروني أو رقم هاتف صالح")
      .required("يجب إدخال البريد الإلكتروني أو رقم الهاتف"),
    password: Yup.string()
      .min(8, "يجب أن تكون كلمة المرور 8 أحرف على الأقل")
      .max(20, "لا يجب أن تزيد كلمة المرور عن 20 أحرف")
      .required("يجب إدخال كلمة المرور"),
  });

  async function submitForm(values) {
    setLoading(true);
    const { data } = await axios.post(
      "https://development.feastap.com/api/login",
      values
    );

    if (data?.status === 200) {
      setLoading(false);
      toast.success(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
      localStorage.setItem("token", data?.data.token);
      navigate("/menu");
    } else if (data?.status === 101) {
      setLoading(false);
      toast.error(data?.message, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    } else {
      setLoading(false);
      toast.success(data?.data.otp, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 5000,
        closeOnClick: false,
      });
      localStorage.setItem("token", data?.data.token);
      localStorage.setItem("mobile", data?.data.mobile);
      setTimeout(() => {
        navigate("/otp");
      }, 10000);
    }
  }

  let formik = useFormik({
    initialValues: {
      login: "",
      password: "",
      role: "user",
    },
    validationSchema,
    onSubmit: submitForm,
  });

  return (
    <>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <h3>مرحبا بعودتكم لتطبيق مينيو آب</h3>

          {/* login */}
          <div className="input-container">
            <label htmlFor="login">البريد الإلكتروني أو رقم الهاتف</label>
            <div className="input-wrapper">
              <Mail className="input-icon" />
              <input
                placeholder="البريد الإلكتروني أو رقم الهاتف"
                onBlur={formik.handleBlur}
                id="login"
                name="login"
                onChange={formik.handleChange}
                value={formik.values.login}
              />
            </div>
            {formik.errors.login && formik.touched.login ? (
              <p className="error-danger">{formik.errors.login}</p>
            ) : (
              ""
            )}
          </div>

          {/* password */}
          <div className="input-container">
            <label htmlFor="password">كلمة المرور</label>
            <div className="input-wrapper">
              <LockKeyhole className="input-icon" />
              <input
                placeholder="كلمة المرور"
                type={showPassword ? "password" : "text"}
                onBlur={formik.handleBlur}
                id="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
              />
              {showPassword ? (
                <Eye
                  className="input-icon-eye"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <EyeOff
                  className="input-icon-eye"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            {formik.errors.password && formik.touched.password ? (
              <p className="error-danger">{formik.errors.password}</p>
            ) : (
              ""
            )}
          </div>

          {isLoading ? (
            <button className="btn-form">
              <RotatingLines
                strokeColor="white"
                strokeWidth="5"
                animationDuration="0.75"
                width="30"
                visible={true}
              />
            </button>
          ) : (
            <button
              type="submit"
              disabled={!(formik.isValid && formik.dirty)}
              className="btn-form"
            >
              تسجيل الدخول
            </button>
          )}

          <div className="to-login">
            <p> مستخدم جديد</p>
            <Link to="/register">أنشأ حساب</Link>
          </div>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}
