import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
// import './menue.css'

import restu from "../../assets/images/restu.png";
import location from "../../assets/images/material-symbols_location-on.png";
import burger from "../../assets/images/Rectangle 70.png";
import Accordion from "react-bootstrap/Accordion";
import { Button } from "react-bootstrap";
import axios from "axios";
import axiosInstance from "../../axiosConfig/axios";
import Loader from "../../components/loader/loader";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const AfterAddingOrder = () => {
  const navigate = useNavigate();
  const url = "#";
  const [info, setInfo] = useState([]);
  const [firstCategory, setfirstCategory] = useState([]);
  const [menuCategorys, setMenuCategorys] = useState([]);
  const [menuProducts, setMenuProducts] = useState([]);
  const [buttonClasses, setButtonClasses] = useState([]);
  const [CartProducts, setCartProducts] = useState([]);
  const [Total, setTotal] = useState([]);

  const url1 = "https://development.feastap.com/api/";
  const token = "411|bRXOXJZDl2I0PGARrxRl2psD4L4FdcaXnSmE3xel";
  const token1 = localStorage.getItem("accessToken");

  const tableId = localStorage.getItem("table");
  const restaurantId = localStorage.getItem("restaurant");
  useEffect(() => {
    const requestBody = {
      table_id: tableId,
      restaurant_id: restaurantId,
    };

    axiosInstance
      .post("user/scan-qr-code", requestBody)
      .then(function (res) {
        console.log(res.data.data.resturant.data.menuCategory);
        console.log(res.data.data.resturant.data.menuProducts);

        setInfo(res.data.data.resturant.data.info);
        setfirstCategory(res.data.data.resturant.data.menuCategory[0].name);
        setMenuCategorys(res.data.data.resturant.data.menuCategory);
        setMenuProducts(res.data.data.resturant.data.menuProducts);
        setButtonClasses(
          res.data.data.resturant.data.menuProducts.map(() => "btn2")
        );
      })
      .catch(function (err) {
        console.log(err);
      });

    axiosInstance
      .get("user/cart")
      .then(function (res) {
        setCartProducts(res.data.data.products);
        setTotal(res.data.data.summary.subTotal);
      })
      .catch(function (err) {
        console.log(err);
      });
  }, []);

  const handleButtonClick = (index, Product) => {
    setButtonClasses((prevClasses) => {
      const updatedClasses = [...prevClasses];
      updatedClasses[index] =
        updatedClasses[index] === "btn1" ? "btn2" : "btn1";
      return updatedClasses;
    });

    // window.location.href = `/meal/${Product.id}`
  };
  const loader = useSelector((state) => state.loader.loader);

  return (
    <>
      <div className="contain content--center">
        {loader && <Loader />}
        {!loader && info && (
          <>
            <img
              src={info.Logo}
              alt="Avatar"
              style={{
                width: "70px",
                margin: "0 20",
                marginBottom: "18px",
                borderRadius: "8px",
                height: "70px",
              }}
            />
            <h3
              style={{
                margin: "0",
                fontWeight: "900",
                fontSize: "16px",
                fontFamily: "Tajawal",
              }}
            >
              {info.name}
              <span
                className="dot"
                style={{ marginRight: "10px", verticalAlign: "middle" }}
              ></span>
            </h3>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "400",
                color: "#838383",
                margin: "0",
              }}
            >
              {info.categories}
            </p>
            <p
              style={{
                fontSize: "15px",
                fontWeight: "400",
                color: "#838383",
                margin: "0",
              }}
            >
              <img
                src={location}
                alt="..."
                style={{
                  width: "auto",
                  height: "20px",
                  margin: "0",
                  marginTop: "5px",
                  marginLeft: "5px",
                }}
              />{" "}
              {info.address}
            </p>
          </>
        )}
      </div>
      <hr style={{ Rotation: "-0 deg", marginTop: "15px" }} />

      {menuCategorys.slice(1).map((menuCategory, categoryIndex) => (
        //alwaysOpen
        <Accordion
          defaultActiveKey={["1"]}
          style={{
            marginLeft: "30px",
            marginRight: "30px",
            marginBottom: "15px",
          }}
          key={categoryIndex}
        >
          <Accordion.Item eventKey="1">
            <Accordion.Header style={{ fontWeight: "900" }}>
              {menuCategory.name}
            </Accordion.Header>
            <Accordion.Body>
              {menuProducts.map((menuProduct, productIndex) => {
                const isProductInCart = CartProducts.some(
                  (item) => item.name == menuProduct.name
                );

                if (menuProduct.category == menuCategory.name) {
                  return (
                    <div
                      className="contain content--center"
                      style={{
                        padding: "20px",
                        paddingBottom: "0px",
                        paddingTop: "20px",
                      }}
                      key={productIndex}
                    >
                      <img
                        src={menuProduct.image}
                        alt="Avatar"
                        onClick={() =>
                          (window.location.href = `/meal/${menuProduct.id}`)
                        }
                        style={{
                          width: "80px",
                          margin: "0 10",
                          borderRadius: "8px",
                          height: "60px",
                        }}
                      />
                      <h3
                        style={{
                          margin: "0",
                          fontWeight: "900",
                          fontSize: "16px",
                          fontFamily: "Tajawal",
                        }}
                      >
                        <a
                          style={{ color: "black", textDecoration: "none" }}
                          href={`/meal/${menuProduct.id}`}
                        >
                          {menuProduct.name}{" "}
                        </a>
                        {isProductInCart ? (
                          <Link
                            to={`/meal/${menuProduct.id}`}
                            type="button"
                            className="btn btn1"
                            onClick={() =>
                              handleButtonClick(productIndex, menuProduct)
                            }
                          >
                            +
                          </Link>
                        ) : (
                          <Link
                            to={`/meal/${menuProduct.id}`}
                            type="button"
                            className="btn btn2"
                            onClick={() =>
                              handleButtonClick(productIndex, menuProduct)
                            }
                          >
                            +
                          </Link>
                        )}
                      </h3>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "#838383",
                          margin: "0",
                        }}
                        className="text-truncate"
                      >
                        {" "}
                        {menuProduct.description}
                      </p>
                      <p
                        style={{
                          fontSize: "15px",
                          fontWeight: "400",
                          color: "rgb(55, 189, 107)",
                          margin: "0",
                        }}
                      >
                        {" "}
                        {menuProduct.price} ر.س
                      </p>
                      <hr style={{ marginTop: "10px", marginBottom: "0" }} />
                    </div>
                  );
                }
              })}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}

      <div
        className=""
        style={{
          fontFamily: "Tajawal",
          backgroundColor: "#37BD6B",
          textAlign: "center",
          margin: "0",
          display: "flex",
          height: "56px",
          justifyContent: "center",
          paddingTop: "10px ",
          paddingBottom: "10px",
          marginBottom: "15px",
        }}
      >
        <p
          style={{
            fontSize: "15px",
            fontWeight: "bolder",
            color: "#ffffff",
            marginTop: "5px",
            marginLeft: "79px",
          }}
        >
          {" "}
          الإجمالى : {Total} ر.س{" "}
        </p>

        <Link
          style={{
            backgroundColor: "#ffffff",
            textAlign: "center",
            border: "none",
            padding: "0 12px",
            color: "#37BD6B",
            fontSize: "15px",
            fontWeight: "bolder",
            height: "36px",
          }}
          to={`/card`}
        >
          {" "}
          اذهب الى السلة{" "}
        </Link>
      </div>

      <Accordion
        defaultActiveKey={["0"]}
        style={{
          marginLeft: "30px",
          marginRight: "30px",
          marginBottom: "15px",
        }}
      >
        <Accordion.Item eventKey="0">
          <Accordion.Header style={{ fontWeight: "900" }}>
            {firstCategory}
          </Accordion.Header>
          <Accordion.Body>
            {menuProducts.map((menuProduct, productIndex) => {
              const isFirstIndex = productIndex === 0;
              const containerStyle = {
                padding: "20px",
                paddingBottom: "0",
                paddingTop: isFirstIndex ? "20px" : "0",
              };

              if (menuProduct.category == firstCategory) {
                const isProductInCart = CartProducts.some(
                  (item) => item.name == menuProduct.name
                );
                return (
                  <div
                    className="contain content--center"
                    style={containerStyle}
                    key={productIndex}
                  >
                    <img
                      src={menuProduct.image}
                      alt="Avatar"
                      onClick={() => navigate(`/meal/${menuProduct.id}`)}
                      style={{
                        width: "80px",
                        margin: "0 10",
                        borderRadius: "8px",
                        height: "60px",
                      }}
                    />
                    <h3
                      style={{
                        margin: "0",
                        fontWeight: "900",
                        fontSize: "16px",
                        fontFamily: "Tajawal",
                      }}
                    >
                      <Link
                        style={{ color: "black", textDecoration: "none" }}
                        to={`/meal/${menuProduct.id}`}
                      >
                        {menuProduct.name}{" "}
                      </Link>
                      {isProductInCart ? (
                        <Link
                          to={`/meal/${menuProduct.id}`}
                          type="button"
                          className="btn btn1"
                          onClick={() =>
                            handleButtonClick(productIndex, menuProduct)
                          }
                        >
                          +
                        </Link>
                      ) : (
                        <Link
                          to={`/meal/${menuProduct.id}`}
                          type="button"
                          className="btn btn2"
                          onClick={() =>
                            handleButtonClick(productIndex, menuProduct)
                          }
                        >
                          +
                        </Link>
                      )}
                    </h3>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "#838383",
                        margin: "0",
                      }}
                      className="text-truncate"
                    >
                      {" "}
                      {menuProduct.description}
                    </p>
                    <p
                      style={{
                        fontSize: "15px",
                        fontWeight: "400",
                        color: "rgb(55, 189, 107)",
                        margin: "0",
                      }}
                    >
                      {" "}
                      {menuProduct.price} ر.س
                    </p>
                    <hr style={{ marginTop: "10px" }} />
                  </div>
                );
              }
            })}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default AfterAddingOrder;
