import React from 'react';
import './oncash.css'
import waiter from '../../assets/images/waiter.png'
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';




const Oncash = () => {
    const url = '#';



    return (
        <>

            <nav className="navbar" style={{ backgroundColor: "#37BD6B", textAlign: "center", margin: "0", display: "block" }}  >
                <div className="">
                    <p style={{ fontSize: "18px", fontWeight: "500", color: "#ffffff", marginBottom: "0" }}> دفع كاش  </p>
                </div>
            </nav>

            <img style={{ marginTop: "150px", marginBottom: "20px" }} src={waiter} alt='...' className="center" />


            <Link className='btn' to={url} style={{ backgroundColor: "#37BD6B", textAlign: "center", margin: "1rem", border: "none", padding: "10px 7px", width: "91.3%", fontWeight: "500" }}  > أذهب الى الدفع </Link>



        </>
    );
};

export default Oncash;
