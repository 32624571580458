import React, { useState } from "react";
import google from "../../assets/images/google.png";
import apple from "../../assets/images/apple.png";
import { Container, Row } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import axiosInstance from "../../axiosConfig/axios";
import { localStorageService } from "../../Helpers/localstorageService";
function prettyParamText(text) {
  return parseInt(text);
}
const Downloading = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(true);
  const [restaurantQuery] = useState({
    table_id: searchParams.get("table"),
    restaurant_id: searchParams.get("restaurant"),
  });

  localStorageService.setItem(
    "table",
    prettyParamText(searchParams.get("table"))
  );
  localStorageService.setItem(
    "restaurant",
    prettyParamText(searchParams.get("restaurant"))
  );

  const [restaurantData, setRestaurantData] = useState(null);

  const feasturl = "https://feastap.com/home.html/";
  useEffect(() => {
    const controller = new AbortController();
    axiosInstance
      .post("user/scan-qr-code", restaurantQuery, {
        signal: controller.signal,
      })
      .then(function (res) {
        setRestaurantData(res.data.data);
        setIsLoading(false);
      })
      .catch(function (err) {
        if (err.code != "ERR_CANCELED") {
          toast.error("tableId or restaurantId is null.", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
          // setTimeout(() => {
          //   window.location.href = feasturl;
          // }, 3000);
        }
      });
    return () => {
      controller.abort();
    };
  }, [isLoading]);

  const handleClick = () => {
    // Navigate programmatically
    navigate("/menue", {
      state: { data: restaurantData },
    });
  };

  return (
    <div
      style={{
        textAlign: "center",
        borderTopRightRadius: "16px",
        borderTopLeftRadius: "16px",
        backgroundColor: "white",
        marginTop: "-20px",
        position: "relative",
        zIndex: 1000,
      }}
    >
      <Container>
        <Row style={{ display: "initial" }}>
          <div
            className="text-bread content--center"
            style={{
              textAlign: "center",
            }}
          >
            <h1
              style={{
                fontSize: "20px",
                height: "20px",
                font: "Tajawal",
                color: "#37BD6B",
                fontWeight: "500",
                marginBottom: "22px",
              }}
            >
              {" "}
              حمل التطبيق واستمتع بمميزاتنا
            </h1>
            <p
              style={{
                height: "80px",
                font: "Tajawal",
                fontSize: "14px",
                fontWeight: "400",
                color: "#838383",
                margin: "0 20px",
                marginBottom: "0px",
              }}
            >
              {" "}
              هناك حقيقة مثبتة منذ زمن طويل وهى أن المحتوى المقورء لصفحة ما
              سيلهي القارىء عن التركيز على الشكل الخاجى للنص
            </p>

            <div className="row" style={{ display: "block" }}>
              <Link
                to={
                  "https://play.google.com/store/apps/details?id=com.safwatech.feastap"
                }
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="storeImg"
                  alt="Get it on Google Play"
                  style={{ height: "42px", width: "120px" }}
                  src={google}
                />
              </Link>
              <Link to={"#"}>
                <img
                  src={apple}
                  style={{
                    height: "40px",
                    width: "120px",
                    marginRight: "15px",
                  }}
                  className="storeImg"
                  alt="Get it on apple stor"
                />
              </Link>
            </div>

            <Link
              className={`btn ${!restaurantData ? "disabled" : ""}`}
              style={{
                width: "285px ",
                padding: "18px 0 ",
                color: "white",
                fontWeight: "500",
                borderRadius: "8px",
                border: "none",
                backgroundColor: "#37BD6B",
                font: "Tajawal",
                marginTop: "40px",
              }}
              to={`/menu`}
              state={{ data: restaurantData }}
              // onClick={(e) => {
              //   e.preventDefault();
              //   handleClick();
              // }}
              // type="submit"
            >
              {restaurantData ? " تخطي واذهب الي المنيو" : "جاري تحميل المنيو"}
            </Link>
          </div>
        </Row>
      </Container>

      <ToastContainer />
    </div>
  );
};

export default Downloading;
